import { render, staticRenderFns } from "./SlopedCarousel.vue?vue&type=template&id=8e3bd40e&scoped=true&"
import script from "./SlopedCarousel.vue?vue&type=script&lang=js&"
export * from "./SlopedCarousel.vue?vue&type=script&lang=js&"
import style0 from "./SlopedCarousel.vue?vue&type=style&index=0&id=8e3bd40e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e3bd40e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulImage: require('/var/www/html/preview/components/contentful/Image.vue').default,ContentfulPageThumbnail: require('/var/www/html/preview/components/contentful/PageThumbnail.vue').default,Markdown: require('/var/www/html/preview/components/Markdown.vue').default,DynamicLink: require('/var/www/html/preview/components/DynamicLink.vue').default,IconPrev: require('/var/www/html/preview/components/icons/IconPrev.vue').default,IconNext: require('/var/www/html/preview/components/icons/IconNext.vue').default})
